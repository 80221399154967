// import node modules
import React, { Component } from "react";
import { Router } from "@reach/router";
import { graphql } from "gatsby";

// import components
import SEO from "../components/seo";
import Layout from "../components/Layout/Layout.component";

// import views components
import ProductGroups from "../views/Products/ProductGroups/ProductGroups.component";
import ProductDetails from "../views/Products/ProductDetails/ProductDetails.component";

// import data
import { PRODUCT_DETAILS_DATA } from "../data/Products/ProductDetails.data";

class Products extends Component {
  render() {
    const { data } = this.props;
    const recipes = {
      sandwiches: [
        {
          name: "italian classico <br /> sandwich",
          slug: "italian-sandwich",
          image: data.italian_classico_sandwich_img.childImageSharp.fluid,
          alt_text: "Italian classico sandwich",
        },
        {
          name: "salame grilled cheese",
          slug: "salame-grilled-cheese",
          image: data.salame_grilled_cheese_img.childImageSharp.fluid,
          alt_text: "salame grilled cheese",
        },
        {
          name: "pepperoni <br /> hoagie",
          slug: "pepperoni-hoagie",
          image: data.pepperoni_hoagie_img.childImageSharp.fluid,
          alt_text: "pepperoni hoagie",
        },
        {
          name: "antipasto salame <br /> sandwich",
          slug: "antipasto-salame-sandwich",
          image: data.antipasto_salame_sandwich_img.childImageSharp.fluid,
          alt_text: "Antipasto salame sandwich",
        },
        {
          name: "salame and cheese <br /> croissant sandwich",
          slug: "salame-cheese-croissant-sandwich",
          image: data.salame_cheese_croissant_img.childImageSharp.fluid,
          alt_text: "Salame cheese croissant",
        },
      ],
      pizzas: [
        {
          name: "pepperoni pizza",
          slug: "pepperoni-pizza",
          image: data.pepperoni_pizza_img.childImageSharp.fluid,
          alt_text: "Pepperoni pizza",
        },
        {
          name: "pepperoni and cheese bread",
          slug: "pepperoni-cheese-bread",
          image: data.pepperoni_cheese_bread_img.childImageSharp.fluid,
          alt_text: "pepperoni cheese bread",
        },
        {
          name: "pepperoni flatbread pizza",
          slug: "pepperoni-flatbread-pizza",
          image: data.pepperoni_flatbread_pizza_img.childImageSharp.fluid,
          alt_text: "Pepperoni flatbread pizza",
        },
        {
          name: "meat lovers calzone",
          slug: "meat-lovers-calzone",
          image: data.meat_lovers_calzone_img.childImageSharp.fluid,
          alt_text: "meat lovers calzone",
        },
        {
          name: "salame calzone",
          slug: "salame-calzone",
          image: data.salame_calzone_img.childImageSharp.fluid,
          alt_text: "salame calzone",
        },
      ],
      appetizers: [
        {
          name: "salame and pepperoni charcuterie plate",
          slug: "salame-pepperoni-charcuterie-plate",
          image: data.salame_pepperoni_charcuterie_img.childImageSharp.fluid,
          alt_text: "salame pepperoni charcuterie",
        },
        {
          name: "italian pinwheels",
          slug: "italian-pinwheels",
          image: data.italian_pinwheels_img.childImageSharp.fluid,
          alt_text: "Italian pinwheels",
        },
        {
          name: "hot pizza dip with garlic crostini",
          slug: "hot-pizza-dip-garlic-crostini",
          image: data.hot_pizza_dip_img.childImageSharp.fluid,
          alt_text: "hot pizza dip",
        },
        {
          name: "baked salame chips with honey mustard dip",
          slug: "salame-chips-honey-mustard-dip",
          image: data.baked_salame_chips_img.childImageSharp.fluid,
          alt_text: "Baked salame chips",
        },
        {
          name: "pizza monkey bread",
          slug: "pizza-monkey-bread",
          image: data.pizza_monkey_bread_img.childImageSharp.fluid,
          alt_text: "Pizza monkey bread",
        },
        {
          name: "antipasto skewers",
          slug: "antipasto-skewers",
          image: data.antipasto_skewers_img.childImageSharp.fluid,
          alt_text: "Antipasto skewers",
        },
        {
          name: "pepperoni rolls",
          slug: "pepperoni-rolls",
          image: data.pepperoni_rolls_img.childImageSharp.fluid,
          alt_text: "Pepperoni rolls",
        },
      ],
      entrees: [
        {
          name: "cheesy salame and pepperoni pasta bake",
          slug: "cheesy-salame-pepperoni-pasta-bake",
          image: data.cheesy_pasta_bake_img.childImageSharp.fluid,
          alt_text: "cheesy pasta bake",
        },
        {
          name: "italian marinated bean salad",
          slug: "italian-bean-salad",
          image: data.italian_bean_salad_img.childImageSharp.fluid,
          alt_text: "Italian bean salad",
        },
        {
          name: "hearty italian salame and bean soup",
          slug: "italian-salame-bean-soup",
          image: data.italian_salame_bean_soup_img.childImageSharp.fluid,
          alt_text: "Italian salame bean soup",
        },
        {
          name: "pepperoni mixed greens salad",
          slug: "pepperoni-mixed-greens-salad",
          image: data.pepperoni_mixed_greens_salad_img.childImageSharp.fluid,
          alt_text: "Pepperoni mixed greens salad",
        },
        {
          name: "salame pasta salad",
          slug: "salame-pasta-salad",
          image: data.salame_pasta_salad_img.childImageSharp.fluid,
          alt_text: "Salame pasta salad",
        },
      ],
    };

    const products = {
      sliced_salami: [
        {
          name: "italian dry <br /> salame",
          slug: "sliced-italian-dry-salame",
          image: data.italian_dry_salame_img.childImageSharp.fluid,
          alt_text: "italian dry salame",
        },
        {
          name: "light italian <br /> dry salame",
          slug: "sliced-light-italian-dry-salame",
          image: data.light_italian_dry_salame_img.childImageSharp.fluid,
          alt_text: "light Italian dry salame",
        },
        {
          name: "reduced sodium <br /> italian dry salame",
          slug: "sliced-reduced-sodium-italian-dry-salame",
          image: data.reduced_sodium_italian_dry_salame_img.childImageSharp.fluid,
          alt_text: "reduced sodium Italian dry salame",
        },
        {
          name: "peppered italian <br /> dry salame",
          slug: "sliced-peppered-italian-dry-salame",
          image: data.peppered_italian_dry_salame_img.childImageSharp.fluid,
          alt_text: "peppered Italian dry salame",
        },
        {
          name: "all natural italian <br /> dry salame",
          slug: "sliced-all-natural-italian-dry-salame",
          image: data.all_natural_italian_dry_salame_img.childImageSharp.fluid,
          alt_text: "all natural Italian dry salame",
        },
        {
          name: "italian dry salame & provolone",
          slug: "sliced-italian-dry-salame-provolone",
          image: data.italian_dry_salame_provolone_img.childImageSharp.fluid,
          alt_text: "italian dry salame provolone",
        },
        {
          name: "italian dry salame <br /> medallions",
          slug: "italian-dry-salame-medallions",
          image: data.salame_medallions_img.childImageSharp.fluid,
          alt_text: "italian dry salame medallions",
        },
      ],
      sliced_pepperoni: [
        {
          name: "pepperoni",
          slug: "sliced-pepperoni",
          image: data.PgroupPepperoni.childImageSharp.fluid,
          alt_text: "pepperoni",
        },
        {
          name: "all natural <br /> pepperoni",
          slug: "sliced-all-natural-pepperoni",
          image: data.PgroupUncuredPepperoni.childImageSharp.fluid,
          alt_text: "all natural uncured pepperoni",
        },
        {
          name: "pepperoni <br /> medallions",
          slug: "pepperoni-medallions",
          image: data.PgroupPepperoniMedallion.childImageSharp.fluid,
          alt_text: "pepperoni medallions",
        },
      ],
      whole_chubs: [
        {
          name: "italian dry <br /> salame chub",
          slug: "italian-dry-salame-chubs",
          image: data.PgroupSalameChub.childImageSharp.fluid,
          alt_text: "italian dry salame chub",
        },
      ],
    };

    const productMetaTitle = this.props.params["*"]
      ? ( PRODUCT_DETAILS_DATA[this.props.params["*"]] 
      ? PRODUCT_DETAILS_DATA[this.props.params["*"]].metaTitle
      : "") 
      : "Our Products | Gallo Salame®";
      const productMetaDescription = this.props.params["*"]
      ? ( PRODUCT_DETAILS_DATA[this.props.params["*"]]
      ? PRODUCT_DETAILS_DATA[this.props.params["*"]].metaDescription
      : "") 
      : "Our products are created with high-quality meats, delicious spice blends, and are bursting with flavor. View all our products.";

    return (
      <Layout 
        metaTitle={productMetaTitle} 
        metaDescription={productMetaDescription} 
      >
        <section>
          <Router>
            <ProductGroups 
              productMetaTitle={productMetaTitle}
              productMetaDescription={productMetaDescription}
              products={products} 
              exact
              path="our-products/" 
            />
            <ProductDetails
              productMetaTitle={productMetaTitle}
              productMetaDescription={productMetaDescription}
              products={products}
              recipes={recipes}
              exact
              path="our-products/:productId"
            />
          </Router>
        </section>
      </Layout>
    );
  }
}

export default Products;

export const pageQuery = graphql`
  query productPageQuery {
    italian_dry_salame_img: file(relativePath: { eq: "products/italian-dry-salame.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    light_italian_dry_salame_img: file(
      relativePath: { eq: "products/light-italian-dry-salame.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    all_natural_italian_dry_salame_img: file(relativePath: { eq: "products/all-natural-italian-dry-salame.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_medallions_img: file(relativePath: { eq: "products/salame-medallions.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    reduced_sodium_italian_dry_salame_img: file(relativePath: { eq: "products/reduced-sodium-italian-dry-salame.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    peppered_italian_dry_salame_img: file(relativePath: { eq: "products/peppered-italian-dry-salame.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    italian_dry_salame_provolone_img: file(relativePath: { eq: "products/italian-dry-salame-provolone.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    PgroupPepperoni: file(relativePath: { eq: "products/pepperoni.png" }) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    PgroupUncuredPepperoni: file(
      relativePath: { eq: "products/all-natural-uncured-pepperoni.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    PgroupPepperoniMedallion: file(
      relativePath: { eq: "products/pepperoni-medallions.png" }
    ) {
      childImageSharp {
        fluid(maxHeight: 533, maxWidth: 503, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    PgroupSalameChub: file(relativePath: { eq: "products/italian-dry-salame-chub.png" }) {
      childImageSharp {
        fluid(maxHeight: 300, maxWidth: 225, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    italian_classico_sandwich_img: file(relativePath: { eq: "recipes/Italian-classico-sandwich.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_grilled_cheese_img: file(relativePath: { eq: "recipes/Salame-grilled-cheese.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_hoagie_img: file(relativePath: { eq: "recipes/Pepperoni-hoagie.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    antipasto_salame_sandwich_img: file(relativePath: { eq: "recipes/Antipasto-salame-sandwich.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_cheese_croissant_img: file(relativePath: { eq: "recipes/Salame-cheese-croissant.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_pizza_img: file(relativePath: { eq: "recipes/Pepperoni-pizza.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_cheese_bread_img: file(relativePath: { eq: "recipes/Pepperoni-cheese-bread.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_flatbread_pizza_img: file(relativePath: { eq: "recipes/Pepperoni-flatbread-pizza.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    meat_lovers_calzone_img: file(relativePath: { eq: "recipes/Meat-lovers-calzone.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_calzone_img: file(relativePath: { eq: "recipes/Salame-calzone.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_pepperoni_charcuterie_img: file(relativePath: { eq: "recipes/Salame-pepperoni-charcuterie.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    italian_pinwheels_img: file(relativePath: { eq: "recipes/Italian-pinwheels.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    hot_pizza_dip_img: file(relativePath: { eq: "recipes/Hot-pizza-dip.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    baked_salame_chips_img: file(relativePath: { eq: "recipes/Baked-salame-chips.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pizza_monkey_bread_img: file(relativePath: { eq: "recipes/Pizza-monkey-bread.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    antipasto_skewers_img: file(relativePath: { eq: "recipes/Antipasto-skewers.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_rolls_img: file(relativePath: { eq: "recipes/Pepperoni-rolls.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    cheesy_pasta_bake_img: file(relativePath: { eq: "recipes/Cheesy-pasta-bake.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    italian_bean_salad_img: file(relativePath: { eq: "recipes/Italian-bean-salad.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    italian_salame_bean_soup_img: file(relativePath: { eq: "recipes/Italian-salame-bean-soup.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    pepperoni_mixed_greens_salad_img: file(relativePath: { eq: "recipes/Pepperoni-mixed-greens-salad.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }

    salame_pasta_salad_img: file(relativePath: { eq: "recipes/Salame-pasta-salad.png" }) {
      childImageSharp {
        fluid(quality: 80) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  }
`;
