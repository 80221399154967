// import node modules
import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Row,
  Col,
  Collapse,
} from "reactstrap";
import { BsFillCaretDownFill, BsFillCaretRightFill } from "react-icons/bs";
import { NutritionFacts } from "@npm/react-component-library";

class ItemInfoContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "DESCRIPTION",
      activeCollapseTab: "DESCRIPTION",
    };
  }
  toggle = (activeTab) => {
    this.setState({ activeTab });
  };

  toggleCollapse = (activeCollapseTab) => {
    this.setState({ activeCollapseTab });
  };
  createMarkup(tag) {
    return { __html: tag };
  }
  render() {
    const { activeTab, activeCollapseTab } = this.state;
    const {
      productDescription,
      productIngredient,
      productNutritionPanel,
    } = this.props;
    return (
      <div className="gagl-iic">
        <div className="gagl-iic-tabnav__wrapper">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={
                  activeTab === "DESCRIPTION" ? "active" : "not-active"
                }
                onClick={() => {
                  this.toggle("DESCRIPTION");
                }}
              >
                DESCRIPTION
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={
                  activeTab === "INGREDIENTS" ? "active" : "not-active"
                }
                onClick={() => {
                  this.toggle("INGREDIENTS");
                }}
              >
                INGREDIENTS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "NUTRITION" ? "active" : "not-active"}
                onClick={() => {
                  this.toggle("NUTRITION");
                }}
              >
                NUTRITION
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent
            className="gagl-iic-tabnav__content"
            activeTab={activeTab}
          >
            <TabPane tabId="DESCRIPTION">
              <Row className="md-0">
                <Col sm="12">
                  <p
                    className="gagl-iic--para"
                    dangerouslySetInnerHTML={this.createMarkup(
                      productDescription.text
                    )}
                  ></p>
                  <ul>
                    {productDescription.list.map((itemContent) => {
                      return <li key={itemContent}>{itemContent}</li>;
                    })}
                  </ul>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="INGREDIENTS">
              <Row className="md-0">
                <Col sm="12">
                  {productIngredient.text}
                  {/* <p className="p-0 gagl-iic--para">
                    <span className="gagl-iic-topic-highlight">Allergens:</span>
                    Milk
                  </p> */}
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="NUTRITION">
              <Row className="md-0 gagl-iic-nutrition-desktop">
                <Col sm="12" className="gagl-iic-nutrition-wrapper">
                  <NutritionFacts
                    servings={productNutritionPanel["servings"]}
                    servingSize={productNutritionPanel["servingSize"]}
                    calories={productNutritionPanel["calories"]}
                    totalFat={productNutritionPanel["totalFat"]}
                    totalFatPercent={productNutritionPanel["totalFatPercent"]}
                    satFat={productNutritionPanel["satFat"]}
                    satFatPercent={productNutritionPanel["satFatPercent"]}
                    transFat={productNutritionPanel["transFat"]}
                    polyUnsatFat={productNutritionPanel["polyUnsatFat"]}
                    monoUnsatFat={productNutritionPanel["monoUnsatFat"]}
                    cholesterol={productNutritionPanel["cholesterol"]}
                    cholesterolPercent={
                      productNutritionPanel["cholesterolPercent"]
                    }
                    sodium={productNutritionPanel["sodium"]}
                    sodiumPercent={productNutritionPanel["sodiumPercent"]}
                    carbohydrate={productNutritionPanel["carbohydrate"]}
                    carbohydratePercent={
                      productNutritionPanel["carbohydratePercent"]
                    }
                    dietaryFiber={productNutritionPanel["dietaryFiber"]}
                    dietaryFiberPercent={
                      productNutritionPanel["dietaryFiberPercent"]
                    }
                    totalSugars={productNutritionPanel["totalSugars"]}
                    addedSugars={productNutritionPanel["addedSugars"]}
                    addedSugarsPercent={
                      productNutritionPanel["addedSugarsPercent"]
                    }
                    protein={productNutritionPanel["protein"]}
                    vitaminD={productNutritionPanel["vitaminD"]}
                    vitaminDPercent={productNutritionPanel["vitaminDPercent"]}
                    vitaminA={productNutritionPanel["vitaminA"]}
                    vitaminAPercent={productNutritionPanel["vitaminAPercent"]}
                    vitaminC={productNutritionPanel["vitaminC"]}
                    vitaminCPercent={productNutritionPanel["vitaminCPercent"]}
                    calcium={productNutritionPanel["calcium"]}
                    calciumPercent={productNutritionPanel["calciumPercent"]}
                    iron={productNutritionPanel["iron"]}
                    ironPercent={productNutritionPanel["ironPercent"]}
                    potassium={productNutritionPanel["potassium"]}
                    potassiumPercent={productNutritionPanel["potassiumPercent"]}
                  />
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>

        <div className="gagl-iic-tabcollapse__wrapper">
          <div className="gagl-iic-tabcollapse__content">
            <Button
              color="default"
              className={
                activeCollapseTab === "DESCRIPTION" ? "active" : "not-active"
              }
              onClick={() => {
                this.toggleCollapse("DESCRIPTION");
              }}
            >
              DESCRIPTION
              <span className="gagl-iic-tabcollapse__icon">
                {activeCollapseTab === "DESCRIPTION" ? (
                  <BsFillCaretDownFill />
                ) : (
                  <BsFillCaretRightFill />
                )}
              </span>
            </Button>
            <Collapse isOpen={activeCollapseTab === "DESCRIPTION"}>
              <Row className="md-0">
                <Col sm="12">
                  <p
                    className="gagl-iic--para"
                    dangerouslySetInnerHTML={this.createMarkup(
                      productDescription.text
                    )}
                  ></p>
                  <ul>
                    {productDescription.list.map((itemContent) => {
                      return <li key={"b" + itemContent}>{itemContent}</li>;
                    })}
                  </ul>
                </Col>
              </Row>
            </Collapse>
          </div>
          <div className="gagl-iic-tabcollapse__content">
            <Button
              color="default"
              className={
                activeCollapseTab === "INGREDIENTS" ? "active" : "not-active"
              }
              onClick={() => {
                this.toggleCollapse("INGREDIENTS");
              }}
            >
              INGREDIENTS
              <span className="gagl-iic-tabcollapse__icon">
                {activeCollapseTab === "INGREDIENTS" ? (
                  <BsFillCaretDownFill />
                ) : (
                  <BsFillCaretRightFill />
                )}
              </span>
            </Button>
            <Collapse isOpen={activeCollapseTab === "INGREDIENTS"}>
              <Row className="md-0">
                <Col sm="12">
                  {productIngredient.text}
                  {/* <p className="gagl-iic--para p-0">
                    <span className="gagl-iic-topic-highlight">Allergens:</span>
                    Milk
                  </p> */}
                </Col>
              </Row>
            </Collapse>
          </div>
          <div>
            <div className="gagl-iic-tabcollapse__content">
              <Button
                color="default"
                className={
                  activeCollapseTab === "NUTRITION" ? "active" : "not-active"
                }
                onClick={() => {
                  this.toggleCollapse("NUTRITION");
                }}
              >
                NUTRITION
                <span className="gagl-iic-tabcollapse__icon">
                  {activeCollapseTab === "NUTRITION" ? (
                    <BsFillCaretDownFill />
                  ) : (
                    <BsFillCaretRightFill />
                  )}
                </span>
              </Button>
            </div>
            <Collapse
              isOpen={activeCollapseTab === "NUTRITION"}
              className="gagl-iic-nutrition-wrapper"
            >
              <NutritionFacts
                servings={productNutritionPanel["servings"]}
                servingSize={productNutritionPanel["servingSize"]}
                calories={productNutritionPanel["calories"]}
                totalFat={productNutritionPanel["totalFat"]}
                totalFatPercent={productNutritionPanel["totalFatPercent"]}
                satFat={productNutritionPanel["satFat"]}
                satFatPercent={productNutritionPanel["satFatPercent"]}
                transFat={productNutritionPanel["transFat"]}
                polyUnsatFat={productNutritionPanel["polyUnsatFat"]}
                monoUnsatFat={productNutritionPanel["monoUnsatFat"]}
                cholesterol={productNutritionPanel["cholesterol"]}
                cholesterolPercent={productNutritionPanel["cholesterolPercent"]}
                sodium={productNutritionPanel["sodium"]}
                sodiumPercent={productNutritionPanel["sodiumPercent"]}
                carbohydrate={productNutritionPanel["carbohydrate"]}
                carbohydratePercent={
                  productNutritionPanel["carbohydratePercent"]
                }
                dietaryFiber={productNutritionPanel["dietaryFiber"]}
                dietaryFiberPercent={
                  productNutritionPanel["dietaryFiberPercent"]
                }
                totalSugars={productNutritionPanel["totalSugars"]}
                addedSugars={productNutritionPanel["addedSugars"]}
                addedSugarsPercent={productNutritionPanel["addedSugarsPercent"]}
                protein={productNutritionPanel["protein"]}
                vitaminD={productNutritionPanel["vitaminD"]}
                vitaminDPercent={productNutritionPanel["vitaminDPercent"]}
                vitaminA={productNutritionPanel["vitaminA"]}
                vitaminAPercent={productNutritionPanel["vitaminAPercent"]}
                vitaminC={productNutritionPanel["vitaminC"]}
                vitaminCPercent={productNutritionPanel["vitaminCPercent"]}
                calcium={productNutritionPanel["calcium"]}
                calciumPercent={productNutritionPanel["calciumPercent"]}
                iron={productNutritionPanel["iron"]}
                ironPercent={productNutritionPanel["ironPercent"]}
                potassium={productNutritionPanel["potassium"]}
                potassiumPercent={productNutritionPanel["potassiumPercent"]}
              />
            </Collapse>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemInfoContainer;
