// import node modules
import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import Carousel from "react-multi-carousel";
import Slider from "react-slick";
import { navigate, Link } from "gatsby";
import Img from "gatsby-image";
import { Redirect } from "@reach/router";

// import App component
import ItemInfoContainer from "../../../components/ItemInfoContainer/ItemInfoContainer.component";
import Util from "../../../Utilities/util";
import SEO from "../../../components/seo"

// import data
import { PRODUCT_DETAILS_DATA } from "../../../data/Products/ProductDetails.data";

const responsive = {
  superLargeDesktop: {
    breakpoint: { min: 1920 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 1920, min: 1440 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1439, min: 1280 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 1279, min: 320 },
    items: 1,
  },
};

function CustomNextArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="fa fa-angle-right gagl-custom-nxt-arrow"
      onClick={onClick}
    />
  );
}

function CustomPrevArrow(props) {
  const { onClick } = props;
  return (
    <div
      className="fa fa-angle-left gagl-custom-prev-arrow"
      onClick={onClick}
    />
  );
}

class ProductDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false
    }
  }
  pageNavigate = pagePath => {
    navigate(pagePath);
  };
  createMarkup(tag) {
    return { __html: tag };
  }

  componentWillMount(){
    const { productId } = this.props;
    if(!PRODUCT_DETAILS_DATA[productId])
    {
      this.setState({ redirect: true });
    }
  }

  render() {

    const { redirect } = this.state;

     if (redirect) {
       return <Redirect noThrow to='/404/' />;
     }
     
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      nextArrow: <CustomNextArrow />,
      prevArrow: <CustomPrevArrow />,
      responsive: [
        {
          breakpoint: 1920,
          settings: {
            slidesToShow: 3,
            initialSlide: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 2,
            initialSlide: 2,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
      ],
    };
    const { productId } = this.props;
    const productMetaData = this.props.products;
    const recipesMetaData = this.props.recipes;
    const PRODUCT_DETAILS_DATA_COPY = PRODUCT_DETAILS_DATA[productId] || {};
    const primaryProductMetaData = Util.getObjectFromSlug(productMetaData, productId);
    const more_recipe_arr = PRODUCT_DETAILS_DATA_COPY.more_recipe;
    const siblingProductMetaData = Util.getSiblingsFromSlug(
      recipesMetaData,
      more_recipe_arr
    );
    const wtbLink = PRODUCT_DETAILS_DATA_COPY.WTBLink;

    return (
      <React.Fragment>
        <SEO title={this.props.productMetaTitle} description={this.props.productMetaDescription} />
        <section className="product-details-wrapper gagl-nav-top-space" role="img"
          aria-label="san francisco golden gate bridge">
          <h1 className="product-title">
            {PRODUCT_DETAILS_DATA_COPY && PRODUCT_DETAILS_DATA_COPY.productName}
          </h1>
          <Row className="m-0">
            <Col xs="12" md="5" lg="5" className="pr-lg-5 pl-lg-5">
              <div className="gagl-product-carousel">
                <Carousel
                  className="mb-md-4 mb-lg-4 mb-3 pb-5"
                  responsive={responsive}
                  showDots={false}
                >
                  <div className="text-center">
                    <Img className={primaryProductMetaData.slug != "italian-dry-salame-chubs" ? "gagl-pd-primary-crsl-img" : "gagl-pd-primary-crsl-vertical-img"}
                      fluid={primaryProductMetaData.image} alt={primaryProductMetaData.alt_text}></Img>
                  </div>
                </Carousel>
              </div>
              <div className="gagl-product-wheretobuy">
                <div className="d-inline-block gagl-product-availability float-left">
                  <label className="gagl-availability-label">Available in:</label>
                  <br></br>
                  <span className="gagl-weight-text">
                    {PRODUCT_DETAILS_DATA_COPY &&
                      PRODUCT_DETAILS_DATA_COPY.availableIn}
                  </span>
                </div>
                <button className="gagl-btn gagl-btn-red mt-mg-2 mt-lg-2"
                >
                  <Link to={wtbLink}>where to buy</Link>
                </button>
              </div>
              <div className="mt-5 d-none d-md-block d-lg-block">
                <div className="gagl-recipes-iteration-header mb-4">
                  recipes you might like
                </div>
                <div className="gagl-recipes-iteration-carousel">
                  <Slider {...settings}>
                    {siblingProductMetaData.map(sliderItem => {
                      return (
                        <div
                          key={sliderItem.slug}
                          className="gagl-recipes-iteration-image"
                          onClick={() => {
                            this.pageNavigate(`/recipes/${sliderItem.slug}/`);
                          }}
                        >
                          <Img
                            className="w-100 h-100 mb-2"
                            fluid={{
                              ...sliderItem.image, aspectRatio: 1
                            }}
                            ref={this.ref}
                            imgStyle={{ objectFit: 'cover' }}
                            alt={sliderItem.alt_text}
                          ></Img>
                          <Link
                            className="gagl-recipes-name"
                            dangerouslySetInnerHTML={this.createMarkup(
                              sliderItem.name
                            )}
                            to={`/recipes/${sliderItem.slug}/`}
                          ></Link>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="text-center mt-4 mb-4">
                  <button
                    className="gagl-btn gagl-btn-red"
                  >
                    <Link to="/recipes/">more recipes</Link>
                  </button>
                </div>
              </div>
            </Col>
            <Col xs="12" md="7" lg="7">
              <ItemInfoContainer
                viewType="DOGGLE"
                productDescription={PRODUCT_DETAILS_DATA_COPY.productDescription}
                productIngredient={PRODUCT_DETAILS_DATA_COPY.productIngredient}
                productNutritionPanel={
                  PRODUCT_DETAILS_DATA_COPY.productNutritionPanel
                } />
            </Col>
            <Col className="d-sm-block d-md-none d-lg-none" xs="12">
              <div className="mt-5">
                <div className="gagl-recipes-iteration-header mb-4">
                  recipes you might like
                </div>
                <div className="gagl-recipes-iteration-carousel">
                  <Slider {...settings}>
                    {siblingProductMetaData.map(sliderItem => {
                      return (
                        <div
                          key={sliderItem.slug}
                          className="gagl-recipes-iteration-image"
                          onClick={() => {
                            this.pageNavigate(`/recipes/${sliderItem.slug}/`);
                          }}
                        >
                          <Img
                            className="w-100 h-100 mb-2"
                            fluid={{
                              ...sliderItem.image, aspectRatio: 1
                            }}
                            ref={this.ref}
                            imgStyle={{ objectFit: 'cover' }}
                            alt={sliderItem.alt_text}
                          ></Img>
                          <Link
                            className="gagl-recipes-name"
                            dangerouslySetInnerHTML={this.createMarkup(
                              sliderItem.name
                            )}
                            to={`/recipes/${sliderItem.slug}/`}
                          ></Link>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
                <div className="text-center mt-4 mb-4">
                  <button
                    className="gagl-btn gagl-btn-red"
                  >
                    <Link to="/recipes/">more recipes</Link>
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </section>
      </React.Fragment>
    );
  }
}

export default ProductDetails;
